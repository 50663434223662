.position-alert{
    position: absolute !important;
    right: 0;
    top: 5%;
}
.icons-sidebar{
    margin-right: 11px;
    position: absolute;
    left: 5%;
    top: 20%;
    width: 20px !important;
}
.bb-1{
    border-bottom: 1px solid;
}
.border-gray{
    border-color: #ddd;
}
.bg-white {
    background: white;
}
.profile-image{
    height: 200px;
    width: 200px;   
    border-radius: 50%;
}
.black-background{
    background: yellow;
}
.cursor-pointer{
    cursor: pointer;
}
.daysCircle{
    border: 1px solid black;
    border-radius: 50%;
    padding: 2px 5px;
    margin-right : 4px;
}
.daysHighlight{
    border : 1px solid #0062cc;
}
.category-tags {
    padding: 5px 8px;
    border: 1px solid #ddd;
    border-radius: 12px;
    margin-right: 10px;
}
.c-alanon-blue{
    color : rgb(62, 163, 252)
}
.cb-alanon-blue{
    background : rgb(62, 163, 252)
}
.csv-input {
    padding: 10px;
    display: block;
    margin: 15px auto;
    border: 1px solid #ccc;
    border-radius: 5px;
}
.emoji-picker-react{
    width : 100% !important
}
span.iconLeft svg {
    margin-left: 0%;
    padding-left: 0%;
}
body::-webkit-scrollbar, .ag-body-horizontal-scroll::-webkit-scrollbar {
    width: 1em;
}
   
  body::-webkit-scrollbar-track, .ag-body-horizontal-scroll::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }
   
  body::-webkit-scrollbar-thumb, .ag-body-horizontal-scroll::-webkit-scrollbar-thumb {
    background-color: rgb(62, 163, 252) !important;
    outline: 1px solid slategrey;
    border-radius: 10px;
  }
  .edituser-modal{
      .modal-content {
          width : 150%;
          left : -25%;
      }
  }
  .logout-button {
    border: none;
    padding-top: 10px;
    text-align: center;
    background: none;
    padding-left: 20% !important ;
  }
  .content-tags > div {
    display: inline-block;
    margin: 10px 10px;
    padding: 6px;
    border: 1px solid grey;
    border-radius: 22%;
    background: #eee;
  }
  .content-tags > span > span {
    border: 1px solid grey;
    border-radius: 50%;
    padding: 0px 2px;
    margin-left: 4px;
  }