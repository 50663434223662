.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #09d3ac;
}

.margin-Top {
  margin: 3px;
}
.margin-Top-Check {
  margin: 7px;
}
/* .heightChange{
  height: 2.9em !important;
} */
.rw-widget > .rw-widget-container {
  width: 100%;
  margin: 0;
  height: 2.9em !important;
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
}
