@import 'settings/variable';  //themes' colors

//vendor...
@import './vendor';
@import './loader.scss';
//base...
@import 'generic/normalize.scss';
@import 'generic/box-sizing.scss';
@import './base/scaffolding';         // styles of base elements
@import './base/typography';          // base styles of h1-h6, p, span
@import './../../node_modules/react-toastify/dist/ReactToastify.css';

//components...
@import './component/card';                   // Card, CardBody from 'reactstrap'
@import './component/collapse';               // components/Collapse.js ('reactstrap')
// @import './component/calendar';               // components/Calendar.js  ('react-big-calendar')
// @import './component/color-picker';           // components/form/*ColorPicker.js ('react-color')
// @import './component/date-picker';            // components/form/*Date*Picker.js ('react-datepicker')
// @import './component/dropdown';               //
// @import './component/dropzone';               // components/form/DropZone*.js ('react-dropzone')
// @import './component/edit-table';             // components/table/EditableTable.js ('react-data-grid')
// @import './component/gallery';                // components/Gallery.js
// @import './component/list';
// @import './component/map';                    // GoogleMap from 'react-google-maps'
// @import './component/modal';                  // components/Modal.js ('reactstrap')
// @import './component/pagination';             // components/Pagination.js ('reactstrap')
// @import './component/panel';                  // components/Panel.js
// @import './component/popover';                // Popover from 'reactstrap'
// @import './component/pricing-card';
// @import './component/product-gallery';        // components/catalog/ProductGallery.js
// @import './component/progress-bar';           // ProgressBar from 'reactstrap'
// @import './component/project-member';         // components/ProjectMember.js
// @import './component/radio-btn';              // components/form/RadioButton.js
// @import './component/scroll';
// @import './component/search-result';          // components/SearchResult.js
// @import './component/slider';                 // components/range_slider/* ('rc-slider')
// @import './component/table';                  // components/Table.js ('reactstrap')
// @import './component/text-editor';            // components/text-editor/TextEditor.js ('react-draft-wysiwyg')
// @import './component/timeline';               // components/TimeLineItem.js
// @import './component/todo';                   // components/ToDo.js
// @import './component/email-confirmation';     // pages/form/wizard_form/*
// @import './component/notification';           // components/Notification.js ('reactstrap')

//pages...
// @import 'containers/cart';                 // pages/e-commerce/cart/*
// @import 'containers/payment';              // pages/e-commerce/payment/*
// @import 'containers/product-card';         // pages/e-commerce/product-page/components/ProductCard.js
// @import 'containers/product-edit';         // pages/e-commerce/product_edit/*
// @import 'containers/products-list';        // pages/e-commerce/product_list/*
// @import 'containers/project-summary';      // pages/default_pages/project_summary/*
// @import 'containers/documentation';

//objects...
@import 'objects/layout.scss';        // layout settings


//components
@import 'containers/profile';              // pages/account/profile/*

@import './component/material-form';          // for 'material-ui'
@import './custom.scss';                        // custom css
@import './component/sidebar';                // components/sidebar/*
@import './component/select-options';         // <select> and <option>
@import './component/tabs';                   // Nav, NavItem, NavLink, TabContent, TabPane from 'reactstrap'
@import './component/time-picker';            // components/form/TimePicker.js ('react-times')
@import './component/toggle-btn';             // components/form/ToggleButton.js
@import './component/tooltip';                // Tooltip from 'reactstrap'
@import './component/topbar';                 // components/topbar/*
@import 'component/btn';                      // Button from 'reactstrap'
// @import './component/carousel';               // components/carousel/* ('react-slick')
@import './component/catalog_item';           // components/catalog/CatalogItems.js
@import './component/chat';                   // components/chat/*
@import './component/check-box';              // components/form/Checkbox.js and other components with checkboxes
@import './component/grid';                   // useless. using for grid presentation
@import './component/inbox';                  // components/inbox/*
@import './component/wizard';                 // pages/form/wizard_form/*
@import './component/load';
@import './component/customizer';
@import './component/form';                   // <form> and all types of inputs
@import 'component/alert';                    // components/Alert.js ('reactstrap')

// containers
@import 'containers/landing';
@import 'containers/dashboard';            // pages/dashboards/*
@import 'containers/invoice';              // pages/default_page/invoice_template
@import 'containers/not-found';            // pages/default_page/404/*

//pages
@import 'containers/account';              // pages/account/* (except profile)